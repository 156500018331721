// Images
import { ReactComponent as ArrowIcon } from "../../assets/icon_right_arrow.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icon_twitter.svg";

// Utils
import {
  handleOverrideClassName,
  handleOverrideConfig,
  handleRedirect,
} from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

const Twitter = ({ item, theme, history, pageName }) => (
  <div
    className={`twitter-component ${handleOverrideClassName(item.classname)}`}
    style={{
      boxShadow:
        handleOverrideConfig(theme.dropShadow, item.dropShadow) &&
        "0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)",
    }}
  >
    <div className="content-section">
      <div className="twitter-account">
        {/* Twitter Logo */}
        <TwitterIcon
          className={`logo-img-icon ${handleOverrideClassName(item.logoImageClassname)}`}
        />

        {/* Feedsource */}
        {item.feedSource && (
          <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>
            {i18n.t(item.feedSource)}
          </h3>
        )}
      </div>

      {/* Title text */}
      {item.title && (
        <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>
          {i18n.t(item.title)}
        </p>
      )}

      <button
        className="action-btn"
        onClick={() => handleRedirect(item.action, history, item.title, pageName)}
      >
        {i18n.t("Twitter.see_more_button")}
        <ArrowIcon />
      </button>
    </div>

    {item.imageURL && (
      <div className="img-card" style={{ background: item.imageURL && `url(${item.imageURL})` }} />
    )}
  </div>
);

export default Twitter;