/* eslint-disable */
import { useEffect, useState } from "react";

// Services
import i18n from "../i18n";

// Lib
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Components
import Row from "./DynamicComponents/Row";

// Utils
import { handleAnalyticsTrack } from "../utils/Analytics";

const ListingPage = () => {
  const { route_id } = useParams();
  const [pageContent, updatePageContent] = useState<any>([]);
  const [theme, updateTheme] = useState({});

  useEffect(() => {
    // To reset scroll position on page transition
    window.scrollTo({top: 0})
  }, [])

  useEffect(() => {
    let tempRoutename = route_id;
    // Get the theme Object

    updateTheme(window.config.theme);

    if (route_id) {
      // match with page id
      if (window.config) {
        Object.keys(window.config).find(
          (key) =>
            key === route_id &&
            updatePageContent((window.config as any)[key])
        );
      }
    } else {
      if (window.config.homepage) {
        tempRoutename = "homepage";
        updatePageContent(window.config.homepage);
      }
    }

    // Analytics

    handleAnalyticsTrack(tempRoutename || "No Route Name", "onLoad_event", "");
  }, [route_id]);

  return (
    <>
      <Helmet title={i18n.t(pageContent.pageTitle)} />
      <div className={pageContent.classname ? pageContent.classname : "" }>
      {pageContent.content_items && pageContent.content_items.length
        ? pageContent.content_items.map((item: any, i: number) => (
            <Row key={i} pageContent={item} theme={theme} pageName={route_id} />
          ))
        : null}
      </div>
    </>
  );
};

export default ListingPage;
