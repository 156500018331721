import React from "react";

// Lib
import { useNavigate } from "react-router-dom";

// Components
import { useTranslation } from "react-i18next";
import RegularRow from "../Reusable/RegularRow";
import ScrollableRow from "../Reusable/ScrollableRow";
import DynamicWidget from "../Reusable/DynamicWidget";
import Button from "../Reusable/Button";

// Utils
import {
  handleOverrideClassName,
  handleOverrideConfig,
  handleRedirect,
} from "../../utils/CommonFunctions";

interface Props {
  theme: any;
  pageContent: any;
  pageName: string | undefined;
}

const Row = ({ pageContent, theme, pageName }: Props) => {
  const [t] = useTranslation("translations");

  const history = useNavigate();
  return (
    <section
      className={`row ${handleOverrideClassName(pageContent?.classname)} ${
        pageContent?.rowType || ""
      } ${pageContent?.rowFormatToUse || ""}`}
      style={{
        background:
          pageContent?.bgImage &&
          pageContent?.bgImage?.path &&
          `url(${pageContent?.bgImage?.path})`,
      }}
    >
      {pageContent?.items || pageContent?.rowType === "DYNAMIC" ? (
        <>
          <div className="header">
            <div className="header-row">
              {/* Title Text */}

              {pageContent.title && (
                <h2
                  className={`header-text ${handleOverrideClassName(
                    pageContent.headerTextClassname
                  )}`}
                >
                  {t(pageContent.title)}
                </h2>
              )}

              {/* Header Button */}

              {handleOverrideConfig(theme.row.showHeaderButton, pageContent.showHeaderButton) && (
                <div className="button-wrapper">
                  <Button
                    buttonType={handleOverrideConfig(
                      theme.row.headerButtonType,
                      pageContent.headerButtonType
                    )}
                    buttonClassname={handleOverrideClassName(pageContent.headerButtonClassname)}
                    buttonText={pageContent.headerButtonText}
                    handleRedirect={() =>
                      handleRedirect(
                        pageContent.button_action,
                        history,
                        pageContent.title,
                        pageName
                      )
                    }
                    showButtonIcon={pageContent.showButtonIcon}
                    buttonIconImage={pageContent.buttonIconImage}
                    buttonIconPosition={pageContent.buttonIconPosition}
                    buttonIconClassname={handleOverrideClassName(pageContent.buttonIconClassname)}
                  />
                </div>
              )}
            </div>

            {/* Subtitle Text */}

            {pageContent.subTitle && (
              <div className={`subTitle ${handleOverrideClassName(pageContent.subTitleClassname)}`}>
                {t(pageContent.subTitle)}
              </div>
            )}

            {/* Description Text */}

            {pageContent.description && (
              <p
                className={`description ${handleOverrideClassName(
                  pageContent.descriptionClassname
                )}`}
              >
                {t(pageContent.description)}
              </p>
            )}
          </div>

          {/* Items for the rows */}

          {pageContent.rowType === "REGULAR" && (
            <RegularRow pageContent={pageContent} theme={theme} pageName={pageName} />
          )}

          {pageContent.rowType === "SCROLLABLE" && (
            <ScrollableRow pageContent={pageContent} theme={theme} pageName={pageName} />
          )}

          {/* Dynamic Widget  */}

          {pageContent.rowType === "DYNAMIC" && (
            <DynamicWidget pageContent={pageContent} theme={theme} pageName={pageName} />
          )}
        </>
      ) : null}
    </section>
  );
}

export default Row;
