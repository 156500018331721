// Utils
import { handleAnalyticsTrack } from "./Analytics";
import { postMessage } from "../services/NativePostMessage";

// Services
import i18n from "../i18n";

interface IButtonAction {
  type: "IN_APP_BROWSER" | "IN_APP" | "OPEN_WALLET" | "CONTACT" | "URL";
  destinationPageId?: string;
  url?: string;
  supportEmail?: string;
  supportEmailSubject?: string;
  supportPhoneNumber?: string;
  device_dependent_url?: boolean;
  android_url?: string;
  ios_url?: string;
}

export const handleOverrideClassName = (pageClassname) => pageClassname || "";

export const handleOverrideConfig = (themeConfig, pageConfig) => pageConfig || themeConfig;

export const limitItems = (items, limit) => (items?.length > limit ? items.slice(0, limit) : items);

export const handleRedirect = (button_action: IButtonAction, history, buttonTitle, pageName) => {
  const tempPagename = pageName || "homepage";

  if (button_action) {
    const url = handleDeviceDependentUrl(button_action);

    if (button_action.type === "URL" || button_action.type === "IN_APP_BROWSER") {
      const webLinkParams = {
        browserType: button_action.type,
        url: url,
        title: buttonTitle,
      };

      postMessage("openWebLink", webLinkParams);
    }

    // handling the separate behaviours of IN_APP
    if (button_action.type === "IN_APP") {
      if (button_action.destinationPageId) {
        history(`/${button_action.destinationPageId}`);
      } else {
        window.location.href = url;
      }
    }

    if (button_action.type === "OPEN_WALLET") {
      if (window.webkit) {
        window.webkit.messageHandlers.ToApp.postMessage("WebPageCallbackOpenWallet");
      }

      if (window.Android) {
        window.Android.openWallet();
      }
    }

    if (button_action.type === "CONTACT") {
      const contactInfo = {
        supportEmail: button_action.supportEmail,
        supportEmailSubject: button_action.supportEmailSubject,
        supportPhoneNumber: button_action.supportPhoneNumber,
      };

      postMessage("showContactSheet", contactInfo);
    }

    // Analytics
    // IOS
    if (window.webkit) {
      window.webkit.messageHandlers.ToApp.postMessage({
        type: "ANALYTICS",
        actions: "onClick_event",
        page: tempPagename,
        reason: `Clicked - ${i18n.t(buttonTitle)} button`,
        button_action,
      });
    } else if (window.Android) {
      // Android
      window.Android.registerAnalyticsEvent(
        "onClick_event",
        tempPagename,
        `Clicked - ${i18n.t(buttonTitle)} button`
      );
    } else {
      // Web
      handleAnalyticsTrack(
        tempPagename,
        "onClick_event",
        `Clicked - ${i18n.t(buttonTitle)} button`
      );
    }
  }
};

export const handleSliceContent = (numberOfItemsToShow, items) => {
  let itemReturn = items;
  if (numberOfItemsToShow && items?.length > numberOfItemsToShow) {
    itemReturn = items.splice(0, numberOfItemsToShow);
  }

  return itemReturn;
};

/**
 * This is a helper function to handle the device dependent url
 * @param button_action IButtonAction
 * @returns url - string
 */
export const handleDeviceDependentUrl = (button_action: IButtonAction) => {
  const url = button_action.device_dependent_url
    ? window.Android
      ? button_action.android_url
      : button_action.ios_url
    : button_action.url;

  return url;
};
